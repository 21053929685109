import {
  StyledText,
  StyledInput,
  StyledButton,
  CustomDialog,
} from 'components/common'
import handleFormSubmit from 'helpers/handleFormSubmit'
import React, { useState } from 'react'
import {
  BannerNormalizer,
  StyledForm,
  Wrapper,
} from './styled-components'

const CheckBoxes = [
  'Training',
  'Technology',
  'Consultancy',
  'Other',
]

const ContactForm = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault()
    setIsOpen(true)
    handleFormSubmit(e, setIsOpen)
  }

  return (
    <>
      <BannerNormalizer />
      <Wrapper>
        <StyledForm onSubmit={handleSubmit}>
          <StyledText
            textAlign="center"
            fontSize="24px"
            className="heading"
          >
            Contact us
          </StyledText>
          <StyledInput
            id="name"
            label="Name*"
            placeholder="Enter your name (Mandatory)"
            type="name"
            required
          />
          <StyledInput
            id="email"
            label="Email address*"
            placeholder="Enter your email address (Mandatory)"
            type="email address"
            required
          />
          <StyledInput
            id="phoneNumber"
            label="Phone number"
            placeholder="Enter your phone number (Optional)"
            type="phone number"
          />
          <StyledInput
            label="What services are you interested in?"
            type="checkbox"
            id="services"
          >
            {CheckBoxes.map((checkbox, index) => {
              return (
                <div key={index}>
                  <input type="checkbox" id={checkbox} />
                  <label htmlFor={checkbox}>
                    {checkbox}
                  </label>
                </div>
              )
            })}
          </StyledInput>
          <StyledInput
            type="textarea"
            label="Want to say something?*"
            id="message"
            placeholder="Enter you text here"
            required
          />
          <StyledButton type="submit">Submit</StyledButton>
        </StyledForm>
        <CustomDialog
          isOpen={isOpen}
          onDismiss={() => setIsOpen(false)}
        />
      </Wrapper>
    </>
  )
}

export default ContactForm
